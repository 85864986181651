import {Get, Post, Sessions} from "@/utils";

/**
 * AU01ランダム数字
 *
 * @returns {AU01}
 */
export const getRandomNum = async (): Promise<any | null> => {
    const res = await Get("/account/randomCode");
    if (res.ok && res.data) {
        return JSON.parse(res.data);
    } else {
        return null;
    }
};

export const loginApi = async (params: {
    randomCodeToken: string;
    signatureData: string;
    walletAddress: string;
}): Promise<any> => {
    let headers = {
        'content-type': 'application/json'
    } as any;
    if (Sessions.read("_3dToken")) {
        headers.Authorization = Sessions.read("_3dToken");
    }
    const res = await Post("/account/bindMetaMask", {
        body: JSON.stringify(params),
        headers: headers
    });
    return res.data;
};

export const address_loginApi = async (token: string): Promise<any> => {
    let headers = {
        'content-type': 'application/json'
    } as any;
    const res = await Post("/account/addr_login", {
        body: JSON.stringify({token: token,}),
        headers: headers,
    });
    return res.data;
};

export const address_checkCorecikaSignData_api = async (randomCodeToken: string,signatureData: string): Promise<any> => {
    let headers = {
        'content-type': 'application/json'
    } as any;
    if (Sessions.read("_3dToken")) {
        headers.Authorization = Sessions.read("_3dToken");
    }
    const res = await Post("/account/checkCorecikaSignData", {
        body: JSON.stringify({
            randomCodeToken: randomCodeToken,
            signatureData: signatureData,
        }),
        headers: headers,
    });
    return res.data;
};

export const AccountUpdate = async (params: {
    nickname: string;
    avatar: number | null;
    publicFlag: boolean | null;
}): Promise<boolean> => {
    debugger
    let headers = {
        'content-type': 'application/json'
    } as any;
    if (Sessions.read("_3dToken")) {
        headers.Authorization = Sessions.read("_3dToken");
    }
    const res = await Post("/account/update", {
        body: JSON.stringify(params),
        headers: headers
    });
    return res.ok;
};
