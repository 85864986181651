//get polygonData
import {add_room, del_room, opensea_tokens} from "@/services/room";
import {GLoginType, NFT_TYPE} from "@/utils/globl";
import {Sessions} from "@/utils/sessions";
import {METADefaultApiUrl, TCBDefaultApiUrl} from "@/constants";
import store from "@/store";
import {Actions} from "@/store/reducers/account";


export async function sTokens(network: string) {
    let tokenLists: any = [];
    let conAddr: string = "";
    const openTokens = await opensea_tokens();
    if (openTokens && openTokens.data) {
        let tks = openTokens.data.filter((item: any) => item.networkId == network)
        if (tks && tks.length > 0) {
            tokenLists = tks[0].tokens;
            conAddr = tks[0].contractAddress;
        }
    }
    return {conAddr, tokenLists};
}

export async function polygonData(address: string) {
    const WALLET_ADDRESS: string = address;
    const {conAddr, tokenLists} = await sTokens("polygon");
    console.log(tokenLists)
    if (address && tokenLists && tokenLists.length > 0 && conAddr) {
        const ot = tokenLists.map((item: any) => item.tokenId);
        console.log(tokenLists)
        const assetsResponse = await fetch(`https://api.polygonscan.com/api?module=account&action=token1155tx&contractaddress=${conAddr}&address=${WALLET_ADDRESS}&startblock=0&endblock=99999999&page=1&offset=100&sort=asc&apikey= `,
            // options,
        ).then(response => response.json());
        if (assetsResponse && assetsResponse.result && assetsResponse.result.length > 0) {
            const tokens = assetsResponse.result.filter((item: any, index: number) => ot.includes(item.tokenID)).map((item: any) => item.tokenID);
            let ts: any = [];
            let t = tokens.reduce((pre: any, cur: any) => {
                if (cur in pre) {
                    pre[cur]++;
                } else {
                    pre[cur] = 1;
                }
                return pre;
            }, {})

            let t1 = Object.keys(t);
            if (t && t1) {
                t1.map((item: any, index: number) => {
                    if (t[item] % 2 !== 0) {
                        ts.push(item)
                    }
                })
                if (ts) {
                    let NftData: any = null;
                    if (tokens && tokens.length > 0) {
                        const nfts: any = (await Promise.all(ts.map(async (item: any, index: number) => {
                                const res = await fetch(`https://api.opensea.io/api/v2/metadata/matic/${conAddr}/`.concat(item),).then(response => {
                                    return response.json();
                                });
                                let params = {
                                    id: item,
                                    name: res.name,
                                    image_url: res.image,
                                    description: res.description,
                                    traits: res.traits,
                                }
                                return new Promise(resolve => {
                                    resolve(params);
                                })
                            }))
                        );
                        if (nfts) {
                            let nft1 = nfts.map((nItem: any, index: number) => {
                                let ns = tokenLists.filter((item: any, index: number) => item.tokenId == nItem.id)
                                if (ns && ns.length > 0) {
                                    nItem.image_url = ns[0].tokenImage;
                                    return nItem;
                                }
                            })
                            NftData = nft1;
                        }
                    }
                    return NftData;
                }

            }
        }
    }
}


export async function getNftList(address: string, walletType: string) {
    if (address && walletType === GLoginType.MATEMASK) {
        let metaNft: any = [];
        let mt: any = [];
        const WALLET_ADDRESS = address;
        const url = process.env.REACT_APP_OPENSEA_BASE_API;
        console.log(url)
        const po = await polygonData(address);
        const {conAddr, tokenLists} = await sTokens("eth");
        if (tokenLists && tokenLists.length > 0) {
            const ot = tokenLists.map((item: any) => item.tokenId);
            const assetsResponse = await fetch(
                `${url}/assets?owner=${WALLET_ADDRESS}&asset_contract_address=${conAddr}&include_orders=false`,
                // options,
            ).then(response => response.json());
            metaNft = assetsResponse.assets.filter((item: any, index: number) => ot.includes(item.token_id));
        }
        mt = metaNft && metaNft.length > 0 ? mt.concat(metaNft) : mt;
        mt = po && po.length > 0 ? mt.concat(po) : mt;
        console.log(mt);
        Sessions.write(NFT_TYPE.METAMASK_NFTS, mt);

        return mt;
    } else if (address && walletType === GLoginType.CORECIKA) {
        const assetsResponse = await fetch(
            TCBDefaultApiUrl + `/bcnft/list?address=${address}`,
            // options,
        ).then(response => response.json());
        console.log(assetsResponse);
        if (assetsResponse.code === 0 && assetsResponse.data) {
            const nfts: any = (await Promise.all(assetsResponse.data.map(async (item: any, index: number) => {
                    const url = item.metadataUrl.substring(item.metadataUrl.indexOf("/token"), item.metadataUrl.length)
                    const res = await fetch(METADefaultApiUrl.concat(url),).then(response => {
                        return response.json();
                    });
                    return new Promise(resolve => {
                        res.image = res.thumbnailImage;
                        const resData = Object.assign(res, item)
                        resolve(resData);
                    })
                }))
            );
            Sessions.write(NFT_TYPE.CORECIKA_NFTS, nfts);
            return nfts;
        }

    }
}


export async function has_nft() {
    debugger
    if (Sessions.read("_3dToken")) {
        let corecikaNfts: any = Sessions.read(NFT_TYPE.CORECIKA_NFTS);
        let metaMaskNfts: any = Sessions.read(NFT_TYPE.METAMASK_NFTS);
        let info = store.getState().account;
        if (info.hasRoom) {
            if ((metaMaskNfts == null || metaMaskNfts.length == 0) && (corecikaNfts == null || corecikaNfts.length == 0)) {
                //del room
                await del_room();
                store.dispatch(Actions.login({
                        hasRoom: false,
                        roomId: null,
                    })
                )
            }
        } else {
            if ((corecikaNfts && corecikaNfts.length > 0) || metaMaskNfts && metaMaskNfts.length > 0) {
                //add room
                let res = await add_room();
                console.log(res)
                store.dispatch(Actions.login({
                        hasRoom: true,
                        roomId: res.data.roomId,
                    })
                )
            }
        }
    }
}