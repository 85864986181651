import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import styles from "../../styles/home.module.scss";
import {Avatar, Button, List, message, notification} from 'antd';
import type {SizeType} from 'antd/es/config-provider/SizeContext';
import Background from '../../assets/home/homeBg.png';
import aboutBackgroundImg from "@/assets/back_u.png"
import {useAccountState, useAppDispatch} from "@/store";
import {RoutePaths} from "@/router";
import {room_list_top} from "@/services/room";
import {UserOutlined} from "@ant-design/icons";
import {GLoginType, getNftList, has_nft} from "@/utils";
import {getRecikaProviderConfig,} from "@/constants";
import {address_loginApi} from "@/services";
import {Actions} from "@/store/reducers/account";
import aboutRoom from "@/assets/aboutRoom.png"
import clsx from "clsx";

const sectionStyle = {
    width: "100%",
    height: "650px",
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
};
const paddingTop60 = {
    paddingTop: "60px"
};

const homeTitleCss = {
    marginTop: "8rem",
    color: "white",
    fontSize: "3.5rem",
    fontWeight: "800",
}
const homeAboutTitleCss = {
    paddingTop: "2rem",
    color: "black",
    fontSize: "1.5rem",
    fontWeight: "800",
}

const homeContextCss = {
    color: "white",
    fontSize: "1rem",
    fontWeight: "500",
    paddingBottom: "30px",
    width: "27%",
    margin: "auto",
}
const homeAboutCss = {
    color: "black",
    fontSize: ".8rem",
    fontWeight: "600",
    padding: "20px 0 20px 0",
    width: "60%",
    margin: "auto",
}

const aboutDiv = {
    width: "100%",
    background: "black",
    height: "340px",
    display: "inline-block",
    borderRadius: "5px",
    backgroundImage: `url(${aboutRoom})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
}

const aboutBackCss = {
    background: "#ece9e1",
    backgroundImage: `url(${aboutBackgroundImg})`,
    backgroundSize: "250px",
    backgroundRepeat: "no-repeat",
    paddingBottom: "2rem",
    backgroundPosition: "90% -10%",
};

function OtherRoom() {
    const [btnFlag, setBtnFlag] = useState("0");

    const [roomList, setRoomList] = useState<any>([]);

    const navigate = useNavigate();

    async function getListRoom() {
        const res = await room_list_top()
        if (res) {
            setRoomList(res.data)
        }
    }

    useEffect(() => {
        getListRoom()

    }, [])

    const toRoom = (roomId: number) => {
        navigate(RoutePaths.ROOM + "/" + roomId);
    }

    const changeBtn = (flag: any) => {
        setBtnFlag(flag)
        if (flag === "1") {
            notification.warning({
                message: 'coming soon',
            });
        }
    }

    return (
        <div style={{background: "black", paddingBottom: "4rem"}}>
            <div className="main-container">
                <h2 className={clsx(styles.homeOtherRoomTitle,"text-white")}>
                    OTHERS’ ROOMS
                </h2>
                <div style={{marginTop: "10px", marginBottom: "30px"}}>
                    <span className={styles.zoomBtnLeft} style={{background: ""}}
                          onClick={() => changeBtn("0")}>
                        <span>RECENT</span>
                    </span>
                    <span className={styles.zoomBtnRight} style={{background: "#7e521c"}}
                          onClick={() => changeBtn("1")}>FAVORITE
                    </span>
                </div>

                <div>
                    <List
                        grid={{gutter: 16, column: 3}}
                        dataSource={roomList}
                        renderItem={(item: any) => (
                            <List.Item>
                                <div className="relative">
                                    <img className="cursor-pointer" src={item.coverImageUrl}
                                         onClick={() => toRoom(item.roomId)} alt=""/>
                                    <div className="text-white mt-2">
                                        <div className="flex items-center text-left">
                                            {item.ownerAvatar === null &&
                                            <Avatar size="large" icon={<UserOutlined/>}/>
                                            }
                                            {item.ownerAvatar !== null &&
                                            <img src={require("@/assets/avatar/" + (Number(item.ownerAvatar)) + ".png")}
                                                 alt="Avatar"
                                                 className="flex-none w-10 h-10 rounded-full object-cover"
                                                 loading="lazy"/>
                                            }
                                            <div className="w-2/4 truncate ml-2">
                                                <div
                                                    className="truncate text-lg font-extrabold">{item.ownerName ? item.ownerName : "xxxx"}</div>
                                                <div
                                                    className="truncate">{item.ownerAddress ? item.ownerAddress : "0x00"}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>

                <div style={{textAlign: "center", marginTop: "15px"}}>
                    <Button type="primary" shape="round" size="large" className="mainBtn disabled-btn">MORE</Button>
                </div>
            </div>
        </div>
    );
}

function Example() {
    const accountState = useAccountState();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    async function coRecikaLink() {
        const porte = await getRecikaProviderConfig();

        await porte.login().then(async (popupResult) => {

            const popRes: any = popupResult;
            const token = popRes.popup.token
            // jwt
            const res = await address_loginApi(token);
            if (res.data) {
                const address = res.data.address
                await getNftList(address, GLoginType.CORECIKA);
                await getNftList(res.data.metaMaskAddr, GLoginType.MATEMASK)
                if (res.data.avatar) {
                    navigate(RoutePaths.HOME);
                    message.info('login success');
                } else {
                    navigate(RoutePaths.PROFILE);
                    message.info('login success');
                }
                dispatch(
                    Actions.login({
                        address: address,
                        chain: null,
                        isMetamask: false,
                        userId: res.data ? res.data.userId : null,
                        avatar: res.data.avatar ? res.data.avatar : null,
                        userName: res.data.avatar ? res.data.nickname : null,
                        walletConnectType: GLoginType.CORECIKA,
                        publicFlag: res.data.publicFlag,
                        roomId: res.data.roomId,
                        roomUrl: res.data.roomUrl,
                        metaMaskAddr: res.data.metaMaskAddr
                    })
                );
                await has_nft();
            } else {
                notification.warning({
                    message: "Login failed!",
                });
            }
        }).catch((e: any) => {
            notification.error({
                message:
                    "Wallet login failed, please confirm your email and pin code then try again!",
            });
        });
    }

    function toRoom() {
        navigate(RoutePaths.PUBLIC_ROOM);
    }

    function toMyRoom() {
        if (accountState && accountState.isLogin) {
            if (!accountState.hasRoom) {
                notification.warning({
                    message: 'Cask NFTをお持ちでない方はmyroomをご利用いただけません。\n' +
                        'Only Cask NFT holders may use My Room',
                });
                //部屋なし
                return;
            }
            navigate(RoutePaths.MY_ROOM + "/" + accountState.roomId)
        } else {
            coRecikaLink();
        }
    }

    const [size] = useState<SizeType>('large');
    return (
        <>
            <div className={styles.homeBgTop} style={sectionStyle}>
                <div style={paddingTop60}>
                    <h2 style={homeTitleCss}>
                        Welcome to
                        <br/>
                        UniCask Community
                    </h2>
                    <div style={homeContextCss}>
                        お持ちのUniCaskアカウント/ウォレットを接続し、
                        <br/>
                        あなただけのUniCaskの世界へ
                        <br/>
                        Connect your UniCask Account
                        <br/>
                        Wallet to enter your own UniCask Community.
                    </div>
                    <div>
                        <Button type="primary" shape="round" size={size} className="mainBtn" onClick={() => toRoom()}>
                            UNICASK ROOM
                        </Button>
                        <Button type="primary" shape="round" size={size} className="mainBtnA"
                                onClick={() => toMyRoom()}>
                            MY ROOM
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

function About() {

    function openUrl(val: string) {
        window.open(val, "_blank")
    }

    return (
        <>
            <div style={aboutBackCss}>
                <div className="main-container">
                    <h2 className={styles.homeOtherRoomTitle}>
                        ABOUT
                    </h2>
                    <div style={homeAboutCss}>
                        UniCask CommunityはUniCask NFTを展示する3D空間。
                        <br/>
                        “UNICASK ROOM”では過去に販売されたNFTが閲覧できます。
                        <br/>
                        UniCask Community is our 3D exhibition space presenting our UniCask NFTs, and there are two
                        different types of space.
                        <br/>
                        "UNICASK ROOM" is where we present our CASK NFTs sold in the past.
                        <br/>
                        <br/>
                        <br/>
                        さらに、CASK NFTのホルダーの方は、UniCaskアカウント、あるいはCASK NFTを保有するウォレットを接続してMY ROOMに入ると、3D空間にあなたのCASK
                        NFTが表示されます。
                        <br/>
                        In "MY ROOM", by connecting your UniCask account or Wallet holding your CASK NFT, you can see
                        your CASK NFT virtually.
                    </div>

                    <div style={{textAlign: "center"}}>
                        <div style={aboutDiv} className="relative">
                            <div className="text-black absolute bottom-px right-px">
                                詳細は<span className="font-bold underline cursor-pointer" onClick={()=> openUrl("https://www.notion.so/unicask/About-UniCask-Community-da127061f4fb48a2a28915e5cfde33d9")}>こちら</span>
                            </div>

                        </div>
                    </div>

                    <div style={homeAboutCss}>
                        <div>
                            アカウントはunicask.comより無料で開設できます。
                        </div>
                        <div>
                            You can open an account for free at unicask.com.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function News() {
    return (
        <div className="main-container" style={{paddingBottom: "4rem"}}>
            <h2 className={clsx(styles.homeOtherRoomTitle,"text-black")}>
                WHAT'S NEW
            </h2>
            <div>
                <div style={{textAlign: "left", color: "black", marginTop: "1rem", marginBottom: "1rem"}}>
                    <h2 className="font-bold">
                        2022.10.21 UniCask Communityのベータ版をリリース
                    </h2>
                    <h2 className="font-bold">
                        2022.10.21 Release of UniCask Community (Beta).
                    </h2>
                </div>
            </div>
        </div>
    )
}

const Home = () => {
        return (
            <>
                <Example/>
                <OtherRoom/>
                <About/>
                <News/>
            </>
        );
    }
;

export default Home;