import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {decrement, increment} from '@/store/reducers/counterSlice'
import {Button} from "antd";
import {collectionName, NFT_TYPE, polygonData, Sessions, sTokens} from "@/utils";

export default function Test() {

    async function getMateData() {
        const res = await polygonData("0x96EAc8295d96Ba2FF848a1f8401C31296cf147D0");
        console.log(res)
    }

    return (
        <div>
            <div className="bg-slate-200">
                <div>
                    <Button onClick={() => getMateData()}>2222</Button>
                </div>
            </div>
        </div>
    )
}