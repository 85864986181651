/* NftDetail.tsx */
import React, {useEffect, useState} from "react";
import {GLoginType, NFT_TYPE, Sessions} from "@/utils";
import {Button} from "antd";
import {Link, useParams} from "react-router-dom";
import {RoutePaths} from "@/router";

interface AnyMap {
    [index: string]: any
}


const NftDetail = () => {

    const [nftData, setNftData] = useState<AnyMap>();

    const params = useParams();

    useEffect(() => {
        const nftMataData: AnyMap = {};
        if (params) {
            debugger
            const id = String(params.id);
            const type = params.type;
            const nftList = type === GLoginType.CORECIKA ? Sessions.read(NFT_TYPE.CORECIKA_NFTS) as any[] : Sessions.read(NFT_TYPE.METAMASK_NFTS) as any[];
            if (id && nftList) {
                const nfts = nftList.filter(item => type === GLoginType.MATEMASK ? String(item.id) === String(id) : String(item.tokenId) === String(id));
                // nftData.userName = "1234";
                if (nfts && nfts.length > 0) {
                    if (type === GLoginType.MATEMASK) {
                        if (nfts[0].traits && nfts[0].traits.length > 0) {
                            nfts[0].traits.map((item: any, index: number) => {
                                debugger
                                if (item.trait_type.toUpperCase() === "CARD") {
                                    nftMataData.cards = item.value;
                                }
                                if (item.trait_type.toUpperCase() === "RARITY") {
                                    nftMataData.rarity = item.value;
                                }
                                if (item.trait_type.toUpperCase() === "CASK") {
                                    nftMataData.cask = item.value;
                                }
                            })
                        }
                    } else {
                        if (nfts[0].attributes && nfts[0].attributes.length > 0) {
                            nftMataData.cards = nfts[0].attributes.filter((item: any) => item.trait_type.toUpperCase() === "CARD") ? nfts[0].attributes.filter((item: any) => item.trait_type.toUpperCase() === "CARD")[0].value : ""
                            nftMataData.rarity = nfts[0].attributes.filter((item: any) => item.trait_type.toUpperCase() === "RARITY") ? nfts[0].attributes.filter((item: any) => item.trait_type.toUpperCase() === "RARITY")[0].value : ""
                            nftMataData.cask = nfts[0].attributes.filter((item: any) => item.trait_type.toUpperCase() === "CASK") ? nfts[0].attributes.filter((item: any) => item.trait_type.toUpperCase() === "CASK")[0].value : ""
                        }
                    }
                    nftMataData.imgUrl = type === GLoginType.MATEMASK ? nfts[0].image_url : nfts[0].image;
                    nftMataData.name = nfts[0].name.indexOf("Serial") > -1 ? nfts[0].name.substring(0, nfts[0].name.indexOf("Serial")) : nfts[0].name;
                    nftMataData.serialId = nfts[0].name.indexOf("Serial") > -1 ? nfts[0].name.substring(nfts[0].name.indexOf("Serial")) : "";
                    //删除描述 前五行
                    let context = "";
                    let linContext = nfts[0].description ? nfts[0].description : ""
                    if (linContext) {
                        let array = linContext.split("\n");
                        array.map((item: any, index: number) => {
                            if (index > 4) {
                                return context = context.concat(item.concat("\n"))
                            }
                            return ""
                        })
                        nftMataData.description = context;
                    }
                    setNftData(nftMataData);
                }
            }
        }
    }, [])

    function changeStr(str: string) {
        console.log(str);
        var s = "";
        if (str.length == 0) return "";
        s = str.replace(/&amp;/g, "&");
        s = s.replace(/&lt;/g, "<");
        s = s.replace(/&gt;/g, ">");
        s = s.replace(/&nbsp;/g, " ");
        s = s.replace(/&#39;/g, "\'");
        s = s.replace(/&quot;/g, "\"");
        s = s.replace(/<br\/>/g, "\n");
        return s;
    }

    return (
        <div className="bg-black minHeight pt-20">
            <div className="main-container">

                {nftData &&
                <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="text-right ">
                        <img src={nftData.imgUrl} alt="" className="w-1/2 inline-block	" loading="lazy"/>
                        <br/>
                        <div className="w-1/2 inline-block text-center mt-10">
                            <Button type="primary" shape="round" size="large" className="mainBtn">
                                <Link to={RoutePaths.MY_PAGE} className="cursor-pointer">Back</Link>
                            </Button>
                        </div>
                    </div>
                    <div className="ml-2 text-left w-1/2 ">
                        <div className="py-1 text-2xl font-semibold">
                            <div>
                                NAME: {nftData.name}
                            </div>
                            <div>{nftData.serialId}</div>
                        </div>
                        <div className="py-1 text-2xl font-semibold">
                            <div>Cards: {nftData.cards}</div>
                            <div>Rarity: {nftData.rarity}</div>
                            <div>CASK: {nftData.cask}</div>
                        </div>
                        <div dangerouslySetInnerHTML={{__html: changeStr(nftData.description)}}></div>
                    </div>
                </div>
                }
            </div>
        </div>
    );
};

export default NftDetail;