import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from "../App";
import Home from "../pages/Home/Home";
import Register from "../pages/Register/Register";
import Room from "../pages/Room/Room";
import NotFound from "../pages/Error/404";
import Test from "../pages/test";
import Profile from "@/pages/Profile/Profile";
import MyPage from "@/pages/Mypage/MyPage";
import NftDetail from "@/pages/NftDetail/NftDetail";
import Nft3dDetail from "@/pages/NftDetail/NftDetail3D"
import DaoVoting from "@/pages/DaoVoting/DaoVoting";
import Mobile from "@/pages/Mobile";

import Ango from "@/pages/Ango3D/Room"

export enum RoutePaths {
    HOME = "/",
    REGISTER = "/register",
    ROOM = "/room",
    PUBLIC_ROOM = "/publicRoom",
    PROFILE = "/profile",
    MY_ROOM = "/myRoom",
    MY_PAGE = "/mypage",
    NOT_FOUND = "/*",
    TEST = "/test",
    NFT_DETAIL = "/nftDetail",
    NFT_3D_DETAIL = "/nft3dDetail",
    DAO_VOTING = "/daoVoting",
    IS_MOBILE = "/mobile",
    ANOGO_3D = "/angoRoom",

}


const renderRouter = () => {
    return (<BrowserRouter key={window.location.pathname}>
            <Routes>
                <Route path="/" element={<App/>}>
                    <Route index element={<Home/>}></Route>
                    <Route path={RoutePaths.REGISTER} element={<Register/>}></Route>
                    <Route path={RoutePaths.PROFILE} element={<Profile/>}></Route>
                    <Route path={RoutePaths.MY_PAGE} element={<MyPage/>}></Route>
                    <Route path={RoutePaths.NFT_DETAIL + "/:id/:type"} element={<NftDetail/>}></Route>
                    <Route path={RoutePaths.DAO_VOTING} element={<DaoVoting/>}></Route>
                </Route>
                {/*3d 用*/}
                <Route path={RoutePaths.NFT_3D_DETAIL} element={<Nft3dDetail/>}></Route>
                <Route path={RoutePaths.IS_MOBILE} element={<Mobile/>}></Route>
                <Route path={RoutePaths.PUBLIC_ROOM} element={<Room/>}>
                </Route>
                <Route path={RoutePaths.DAO_VOTING} element={<DaoVoting/>}></Route>
                <Route path={RoutePaths.ROOM + "/:roomId"} element={<Room/>}>
                </Route>
                <Route path={RoutePaths.MY_ROOM + "/:roomId"} element={<Room/>}>
                </Route>
                <Route path={RoutePaths.ANOGO_3D} element={<Ango/>}>
                </Route>
                <Route path={RoutePaths.TEST} element={<Test/>}>
                </Route>
                <Route path={RoutePaths.NOT_FOUND} element={<NotFound/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default renderRouter