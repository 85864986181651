import {message} from "antd";
import {ethers} from "ethers";

declare let window: any;
const dappLink = "https://metamask.app.link/dapp/" + window.location.host + "/";

export const checkMetamask = () => {
    if (typeof window.ethereum === "undefined") {
        window.open(dappLink);
        message.warning("Metamask isn't installed!");
        return false;
    }
    return true;
};

export const getSigner =
    async (): Promise<null | ethers.providers.JsonRpcSigner> => {
        try {
            if (!checkMetamask()) {
                return null;
            }
            await window.ethereum.request({method: "eth_requestAccounts"});

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            return signer;
        } catch (e) {
            message.error("Request provider failed!");
            return null;
        }
    };
