/* Profile.tsx*/
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {GLoginType, NFT_TYPE, Sessions} from "@/utils";
import {RoutePaths} from "@/router";
import {useAccountState} from "@/store";

const MyPage = () => {
    const [list, setList] = useState([]);
    const [mateList, setMateList] = useState([]);
    const navigate = useNavigate();
    const accountState = useAccountState();

    useEffect(() => {
        if (Sessions.read(NFT_TYPE.CORECIKA_NFTS)) {
            const nfts: any = Sessions.read(NFT_TYPE.CORECIKA_NFTS)
            setList([...nfts] as any);
            console.log(nfts, "mateList")
        }
        if (Sessions.read(NFT_TYPE.METAMASK_NFTS)) {
            const nfts: any = Sessions.read(NFT_TYPE.METAMASK_NFTS)
            setMateList([...nfts] as any);
            console.log(nfts, "mateList")
        }
    }, [])

    function nftClick(id: number, wt: string) {
        navigate(RoutePaths.NFT_DETAIL + "/" + id + "/" + wt);
    }

    // function nftClick(id: number) {
    //     navigate(RoutePaths.NFT_3D_DETAIL + "/" + id + "/" + accountState.walletConnectType + "/" + "0x1AC4EF1B170F010cbc46B7191FfA148b533988D1");
    // }

    return (
        <div className="bg-black minHeight pt-20">
            <div className="main-container">
                <div className="my-4 text-3xl text-white-900 text-left">
                    <span>CORECIKA NFT</span>
                </div>
                <div className="grid grid-cols-6 gap-4">
                    {list && list.map(((item, index) => {
                        return (
                            <div key={index}>
                                <div className="cursor-pointer"
                                     onClick={() => nftClick(item['tokenId'], GLoginType.CORECIKA)}>
                                    <img className="cursor-pointer object-contain" src={item['image']}
                                         alt={item['name']}/>
                                </div>
                            </div>
                        )
                    }))}
                </div>
            </div>

            <div className="main-container">
                <div className="my-4 text-3xl text-white-900 text-left">
                    <span>OPENSEA NFT</span>
                </div>
                <div className="grid grid-cols-6 gap-4">
                    {mateList && mateList.length > 0 && mateList.map(((item, index) => {
                        return (
                            <div key={index}>
                                <div className="cursor-pointer"
                                     onClick={() => nftClick(item['id'], GLoginType.MATEMASK)}>
                                    <img className="cursor-pointer object-contain" src={item['image_url']}
                                         alt={item['name']}/>
                                </div>
                            </div>
                        )
                    }))}
                </div>
            </div>
        </div>
    );
};

export default MyPage;