import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RecikaProvider, Porte} from "@recika/web3-provider";
import {Sessions} from "@/utils";

export interface IAccountState {
    userId: number | null;
    userName: string;
    avatar: number | null;
    isLogin: boolean;
    isMetamask: boolean;
    signed: boolean;
    email: string;
    address: string | null;
    jwt: string | null;
    network: string | null; // supported network
    loading: boolean;
    refresh: boolean; // refresh singnal
    chain: number | null;
    role: number | null;
    loginType: number | null;
    recikaEthAddress: string | null;
    walletConnectType: string | null;
    publicFlag: boolean;
    roomId: number | null;
    roomUrl: string | null;
    metaMaskAddr: string;
    hasRoom: boolean;
}

const initialAccountState = {
    userId: null,
    userName: "",
    avatar: 0,
    isLogin: false,
    isMetamask: false,
    signed: false,
    email: "",
    address: null,
    jwt: null,
    network: null,
    loading: false,
    refresh: false,
    chain: null,
    role: null,
    loginType: null,
    recikaEthAddress: null,
    walletConnectType: null,
    publicFlag: false,
    roomId: null,
    roomUrl: null,
    metaMaskAddr: "",
    hasRoom: false,
} as IAccountState;

export const SessionKey = "io";

export const accountSlice = createSlice({
    name: "account",
    initialState:
        Sessions.decode<IAccountState>(Sessions.read<string>(SessionKey)) ??
        initialAccountState,
    reducers: {
        login: (state, action: PayloadAction<Partial<IAccountState>>) => {
            Object.assign(state, {
                ...state,
                ...action.payload,
                signed: true,
                loading: false,
                isLogin: true,
            });
            Sessions.write(SessionKey, Sessions.encode(state));
        },
        logout: (state) => {
            Porte.disconnect();
            Sessions.remove(SessionKey);
            Sessions.remove("_3dToken");
            Sessions.remove("nftList");
            Object.assign(state, {
                ...initialAccountState,
                chain: state.chain,
                network: state.network,
                signed: false,
                isLogin: false,
            });
        },
        update: (state, action: PayloadAction<Partial<IAccountState>>) => {
            Object.assign(state, {
                ...state,
                ...action.payload,
            });
        },
        refresh: (state) => {
            Object.assign(state, {
                ...state,
                refresh: !state.refresh,
            });
        },
        loading: (state) => {
            Object.assign(state, {
                ...state,
                loading: true,
            });
        },
        done: (state) => {
            Object.assign(state, {
                ...state,
                loading: false,
            });
        },
    },
});

export const Actions = accountSlice.actions;
export default accountSlice.reducer;
