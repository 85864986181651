import React from 'react'
import comingSoon from "@/assets/comingSoon.png"
import {Button} from "antd";

const DaoVoting: React.FC = () => {
    return (
        <div className="bg-black minHeight pt-20">
            <div>
                <img style={{width: '40%', margin: 'auto'}} src={comingSoon}/>
            </div>
            <div style={{textAlign: "center", marginTop: "15px"}}>
                <Button type="primary" shape="round" size="large" className="mainBtn">
                    <a href={"https://discord.com/invite/Bxa3xfhGyJ"}
                       target="_blank"> Go to Discord</a>
                </Button>
            </div>

        </div>
    )
}

export default DaoVoting