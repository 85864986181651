/* NftDetail.tsx */
import React, {useEffect, useState} from "react";
import {polygonData, sTokens} from "@/utils";
import {useLocation} from "react-router-dom";
import {METADefaultApiUrl, TCBDefaultApiUrl} from "@/constants";
import {ExceptionOutlined} from '@ant-design/icons';
import {message} from 'antd';

interface AnyMap {
    [index: string]: any
}

const domain = process.env.REACT_APP_API_DOMAIN;

const copyRoomUrl = domain + "/pagejump/roompage/"

const NftDetail3D = () => {

    const [nftData, setNftData] = useState<AnyMap>();
    const [rId, setRId] = useState<number>();

    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        if (query) {
            const id: string = String(query.get('tokenId'));
            const type: number = Number(query.get('walletType'));
            const address: string = String(query.get('address'));
            const roomId: number = Number(query.get('roomId'));
            setRId(roomId);
            getData(id, type, address);
        }
    }, [])

    async function getData(id: string, type: number, address: string) {
        //matemask
        if (type === 2) {
            const {conAddr, tokenLists} = await sTokens("eth");
            if (tokenLists && tokenLists.length > 0 && conAddr) {
                const ot = tokenLists.map((item: any) => item.tokenId);
                const po = await polygonData(address);
                const WALLET_ADDRESS = address;
                const url = process.env.REACT_APP_OPENSEA_BASE_API;
                const assetsResponse = await fetch(
                    `${url}/assets?owner=${WALLET_ADDRESS}&asset_contract_address=${conAddr}&include_orders=false`,
                    // options,
                ).then(response => response.json());


                const nfts = assetsResponse.assets.filter((item: any, index: number) => ot.includes(item.token_id))

                let nftLists: any = [];
                nftLists = nfts && nfts.length > 0 ? nftLists.concat(nfts) : nftLists;
                nftLists = po && po.length > 0 ? nftLists.concat(po) : nftLists;

                await detail(id, type, nftLists);
            }
        } else if (type === 1) {//corecika
            const assetsResponse = await fetch(
                TCBDefaultApiUrl + `/bcnft/list?address=${address}`,
                // options,
            ).then(response => response.json());
            console.log(assetsResponse);
            if (assetsResponse.code === 0 && assetsResponse.data) {
                const nfts: any = (await Promise.all(assetsResponse.data.map(async (item: any, index: number) => {
                        const url = item.metadataUrl.substring(item.metadataUrl.indexOf("/token"), item.metadataUrl.length)
                        const res = await fetch(METADefaultApiUrl.concat(url),).then(response => {
                            return response.json();
                        });
                        return new Promise(resolve => {
                            res.image = res.thumbnailImage;
                            const resData = Object.assign(res, item)
                            resolve(resData);
                        })
                    }))
                );
                await detail(id, type, nfts);
            }
        }

    }


    function detail(id: string, type: number, nftList: any) {
        const nftMataData: AnyMap = {};
        if (id && nftList) {
            const nfts = nftList.filter((item: any) => type === 2 ? String(item.id) === String(id) : String(item.tokenId) === String(id));
            // nftData.userName = "1234";
            if (nfts && nfts.length > 0) {
                if (type === 2) {
                    if (nfts[0].traits && nfts[0].traits.length > 0) {
                        nfts[0].traits.map((item: any, index: number) => {
                            if (item.trait_type.toUpperCase() === "CARD") {
                                nftMataData.cards = item.value;
                            }
                            if (item.trait_type.toUpperCase() === "RARITY") {
                                nftMataData.rarity = item.value;
                            }
                            if (item.trait_type.toUpperCase() === "CASK") {
                                nftMataData.cask = item.value;
                            }
                        })
                    }
                } else {
                    if (nfts[0].attributes && nfts[0].attributes.length > 0) {
                        nftMataData.cards = nfts[0].attributes.filter((item: any) => item.trait_type.toUpperCase() === "CARD") ? nfts[0].attributes.filter((item: any) => item.trait_type.toUpperCase() === "CARD")[0].value : ""
                        nftMataData.rarity = nfts[0].attributes.filter((item: any) => item.trait_type.toUpperCase() === "RARITY") ? nfts[0].attributes.filter((item: any) => item.trait_type.toUpperCase() === "RARITY")[0].value : ""
                        nftMataData.cask = nfts[0].attributes.filter((item: any) => item.trait_type.toUpperCase() === "CASK") ? nfts[0].attributes.filter((item: any) => item.trait_type.toUpperCase() === "CASK")[0].value : ""
                    }
                }
                nftMataData.imgUrl = type === 2 ? nfts[0].image_url : nfts[0].image;
                nftMataData.name = nfts[0].name.indexOf("Serial") > -1 ? nfts[0].name.substring(0, nfts[0].name.indexOf("Serial")) : nfts[0].name;
                nftMataData.serialId = nfts[0].name.indexOf("Serial") > -1 ? nfts[0].name.substring(nfts[0].name.indexOf("Serial")) : "";
                //删除描述 前五行
                let context = "";
                let linContext = nfts[0].description ? nfts[0].description : ""
                if (linContext) {
                    let array = linContext.split("\n");
                    array.map((item: any, index: number) => {
                        if (index > 4) {
                            return context = context.concat(item.concat("\n"))
                        }
                        return ""
                    })
                    nftMataData.description = context;
                }
                setNftData(nftMataData);
            }
        }
    }

    function closePop() {
        window.parent.postMessage('closePop', '*');
    }

    function copy() {
        if (rId) {
            const content = copyRoomUrl + rId
            let ms = "コピーしました。";
            let aux = document.createElement("input");
            aux.setAttribute("value", content);
            aux.setAttribute('readonly', 'readonly');
            document.body.appendChild(aux);
            aux.setSelectionRange(0, aux.value.length);
            aux.select();
            document.execCommand("copy");
            message.success(ms);
            document.body.removeChild(aux);
        }
    }

    function changeStr(str: string) {
        console.log(str);
        var s = "";
        if (str.length == 0) return "";
        s = str.replace(/&amp;/g, "&");
        s = s.replace(/&lt;/g, "<");
        s = s.replace(/&gt;/g, ">");
        s = s.replace(/&nbsp;/g, " ");
        s = s.replace(/&#39;/g, "\'");
        s = s.replace(/&quot;/g, "\"");
        s = s.replace(/<br\/>/g, "\n");
        return s;
    }

    return (
        <div className="bg-transparent nft-3d-base-layout">
            <div className="nft-3d-div">
                {nftData &&
                <div className="flex flex-row relative">
                    <div className="nft-3d-x">
                    </div>
                    <div className="basis-4/12 relative">
                        <div className="nft-3d-image">
                            <img src={nftData.imgUrl} alt="" className="w-full inline-block" loading="lazy"/>
                        </div>
                    </div>
                    <div className="basis-8/12">
                        <div className="pt-11 pl-2 pr-8 font-semibold tracking-widest text-white text-2xl">
                            {nftData.name}
                        </div>
                        <div className="pt-2 pr-8 text-white text-sm">
                            <div className="p-2">
                                <div className="font-semibold"> Cards</div>
                                <div>{nftData.cards}</div>
                            </div>
                            <div className="p-2">
                                <div className="font-semibold"> Rarity</div>
                                <div>{nftData.rarity}</div>
                            </div>
                            <div className="p-2">
                                <div className="font-semibold"> CASK</div>
                                <div>{nftData.cask}</div>
                            </div>
                            <div className="p-2">
                                <div className="font-semibold"> DISCRIPTION</div>
                                <div style={{minHeight: "180px", height: "180px", overflow: "auto"}}
                                     dangerouslySetInnerHTML={{__html: changeStr(nftData.description)}}></div>
                            </div>
                            <div className="p-2 text-right">
                                <div onClick={() => copy()} className="cursor-pointer	">
                                    <ExceptionOutlined style={{fontSize: '24px', color: 'white'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    );
};

export default NftDetail3D;