import React, {useEffect, useState} from 'react';
import {Col, Row, Popover, Avatar, message, notification, Input, Button} from 'antd';
import styles from "../../styles/header.module.scss";
import {Link, useNavigate} from "react-router-dom";
import classNames from "classnames";
import logo from '../../assets/logo.png'
import head_back from '@/assets/head_back.png'
import {
    getSigner,
    GLoginType,
    Sessions,
    getNftList, has_nft
} from '../../utils'
import {useAccountState, useAppDispatch} from "@/store";
import {RoutePaths} from "@/router";
import {Actions} from "@/store/reducers/account";
import {UserOutlined} from '@ant-design/icons';
import clsx from "clsx";
import {getRecikaProviderConfig} from "@/constants";
import {address_checkCorecikaSignData_api, address_loginApi, getRandomNum, loginApi} from "@/services";

type UserInfo = {
    address: string,
}

const aboutBackCss = {
    backgroundImage: `url(${head_back})`,
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
};


export const wallet_test = process.env.REACT_APP_WALLET_TEST;
console.log(wallet_test, "wallet_test")

const Header = () => {
    const accountState = useAccountState();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [user, setUser] = useState<UserInfo>();

    const [adrInput, setAdrInput] = useState<string | undefined>();

    useEffect(() => {
        console.log(accountState)

        if (Sessions.read("mateKey")) {
            setUser({address: Sessions.read("mateKey") as string})
        }
    }, [])

    function logout() {
        dispatch(Actions.logout());
        sessionStorage.clear();
        navigate(RoutePaths.HOME);
    }

    async function coRecikaLink() {
        const porte = await getRecikaProviderConfig();

        await porte.login().then(async (popupResult) => {
            debugger
            const popRes: any = popupResult;
            const token = popRes.popup.token
            // jwt
            const res = await address_loginApi(token);

            if (res.data) {
                const address = res.data.address
                const recikaNfts = await getNftList(address, GLoginType.CORECIKA);

                const metaNfts = await getNftList(res.data.metaMaskAddr, GLoginType.MATEMASK)


                if (res.data.avatar) {
                    navigate(RoutePaths.HOME);
                    message.info('login success');
                } else {
                    navigate(RoutePaths.PROFILE);
                    message.info('login success');
                }
                dispatch(
                    Actions.login({
                        address: address,
                        chain: null,
                        isMetamask: false,
                        userId: res.data ? res.data.userId : null,
                        avatar: res.data.avatar ? res.data.avatar : null,
                        userName: res.data.avatar ? res.data.nickname : null,
                        walletConnectType: GLoginType.CORECIKA,
                        publicFlag: res.data.publicFlag,
                        roomId: res.data.roomId,
                        roomUrl: res.data.roomUrl,
                        metaMaskAddr: res.data.metaMaskAddr,
                        hasRoom: res.data.hasRoom,
                    })
                );

                await has_nft();
            } else {
                notification.warning({
                    message: "Login failed!",
                });
            }
        }).catch((e: any) => {
            notification.error({
                message:
                    "Wallet login failed, please confirm your email and pin code then try again!",
            });
        });
    }

    async function changeAddress() {

        if (adrInput) {
            dispatch(
                Actions.login({
                    metaMaskAddr: adrInput
                })
            )
            await getNftList(adrInput, GLoginType.MATEMASK);
            await has_nft();
        } else {

            alert("Please enter your address！！！")
        }
    }

    async function metaMaskPay() {
        let signer = await getSigner();
        if (signer) {
            const res01 = await getRandomNum();
            let ethAddress = await signer.getAddress();
            let chainId = await signer.getChainId();
            if (res01.data) {
                let signature = await signer.signMessage(res01.data.randomCode);
                // jwt
                const res02 = await loginApi({
                    randomCodeToken: res01.data.randomCodeToken,
                    signatureData: signature,
                    walletAddress: ethAddress,
                });
                if (res02) {
                    dispatch(
                        Actions.update({
                            metaMaskAddr: ethAddress
                        })
                    )
                    await getNftList(ethAddress, GLoginType.MATEMASK);
                    await has_nft();
                }
            }
        }
    }

    function openUrl(val: string) {
        window.open(val, "_blank")
    }

    async function signMsg() {
        const res01 = await getRandomNum();
        const porte = await getRecikaProviderConfig();
        if (res01.data) {
            let randomCodeToken = res01.data.randomCodeToken;
            let s: any = [res01.data.randomCode]
            await porte.request({method: "personal_sign", params: s}).then(async (popupResult) => {
                debugger
                let res01 = await address_checkCorecikaSignData_api(randomCodeToken, popupResult);
                console.log(res01, popupResult);
                if (res01) {
                    notification.success({
                        message:
                            "sign success!",
                    });
                }
            })
        }
    }

    return <header>
        <div className="fixed top-0 w-full pt-4 z-50" style={aboutBackCss}>
            <Row className="main-container" style={{textAlign: "left"}}>
                <Col className="gutter-row" span={11}>
                    <span >
                        <a href="/">
                            <span className="textFooter">TOP</span>
                        </a>
                    </span>
                    <span className="ml-7">
                        <span className="textFooter text-center cursor-pointer"
                             onClick={() => openUrl("https://www.notion.so/unicask/About-UniCask-Community-da127061f4fb48a2a28915e5cfde33d9")}>ABOUT</span>
                    </span>
                    <span className="ml-7">
                        <a href="https://forms.gle/1iTmTfYiHePfDD1MA" target="_blank">
                            <span className="textFooter text-center">CONTACT</span>
                        </a>
                    </span>
                    <span className="ml-7">
                        <a href="/daoVoting">
                            <span className="textFooter text-center">DAO Voting</span>
                        </a>
                    </span>
                </Col>
                <Col span={2} className="text-center">
                    <div className="logoCenter">
                        <img className={clsx(styles.logoImg)} src={logo} alt={""}/>
                    </div>
                </Col>
                <Col span={5} className="text-center">
                    {accountState.isLogin && !accountState.metaMaskAddr ?
                        <span className="textFooter menu-wallet-small cursor-pointer" style={{padding: '10px'}}
                              onClick={() => metaMaskPay()}>ADD METAMASK</span> : <></>
                    }
                </Col>
                {accountState.isLogin ? <>
                        <Col span={6}
                             className="truncate text-left w-100 h-12 hover:h-max transition menu-hover ease-out duration-300 px-2">
                            <div className="flex items-center space-x-1 cursor-context-menu">
                                {accountState.avatar === null &&
                                <Avatar size="large" icon={<UserOutlined/>}/>
                                }
                                {accountState.avatar !== null &&
                                <img src={require("@/assets/avatar/" + (Number(accountState.avatar)) + ".png")}
                                     alt="Avatar"
                                     className="flex-none w-10 h-10 rounded-full object-cover"
                                     loading="lazy"/>
                                }
                                <div className="w-full truncate">
                                    <div
                                        className="truncate text-lg font-semibold">{accountState.userName ? accountState.userName : "xxx"}</div>
                                    <div
                                        className="truncate">PRIVATE:{accountState.address ? accountState.address : "0x00"}</div>
                                </div>
                            </div>

                            <div className="pl-9">
                                <div className="p-1">
                                    <Link className="font-semibold text-white" to={RoutePaths.PROFILE}>SET UP</Link>
                                </div>
                                {accountState.hasRoom &&
                                <div className="p-1">
                                    <a className="font-semibold text-white"
                                       href={RoutePaths.MY_ROOM + "/" + accountState.roomId}>MY ROOM</a>
                                </div>
                                }
                                <div className="p-1">
                                    <Link className="font-semibold text-white" to={RoutePaths.MY_PAGE}>MY COLLECTION</Link>
                                </div>
                                <div className="p-1">
                                    {accountState.metaMaskAddr &&
                                    <>
                                        <div className="font-semibold text-white">Public Chain Wallet</div>
                                        <div className="truncate text-xs mb-1">
                                            <a href={"https://etherscan.io/address/" + accountState.metaMaskAddr}
                                               target="_blank">
                                                {accountState.metaMaskAddr}
                                            </a>
                                        </div>
                                        <a onClick={() => metaMaskPay()}
                                           className="text-xs text-white font-semibold menu-wallet-small cursor-pointer">
                                            add public chain wallet
                                        </a>
                                    </>
                                    }
                                </div>
                                {wallet_test === "true" && <div className="p-1">
                                    <Input.Group compact>
                                        <Input style={{width: '50%'}} value={adrInput}
                                               onChange={(event => setAdrInput(event.target.value))}/>
                                        <Button type="primary" onClick={() => changeAddress()}>✔</Button>
                                    </Input.Group>
                                </div>
                                }
                                {wallet_test === "true" && <div className="p-1">
                                    <a className="font-semibold text-white" onClick={() => signMsg()}>SIGN MSG</a>
                                </div>
                                }
                                <div className="p-1">
                                    <a className="font-semibold text-white" onClick={() => logout()}>LOGOUT</a>
                                </div>
                            </div>
                        </Col>
                    </>
                    :
                    <>
                        <Col className="gutter-row text-right" span={6}>
                            {/*<Link to="/login" className="text-white hover:text-white">*/}
                            <span className={classNames(styles.hwObQA, "textFooter")}
                                  onClick={() => coRecikaLink()}>
                                CONNECT WALLET
                            </span>
                            {/*</Link>*/}
                        </Col>
                    </>
                }
            </Row>
        </div>
    </header>
}

export default Header