import {DefaultApiUrl} from "../constants";
import {Sessions} from "@/utils/sessions";
import {Actions} from "@/store/reducers/account";
import {message} from "antd";

export type ResponseBody = {
    ok: boolean;
    data?: string;
    error?: any;
};

export async function Get(
    url: string,
    data?: any,
    headers?: any
): Promise<ResponseBody> {
    try {
        const query_string = _toQueryString(data ?? {});
        const rst = await fetch(DefaultApiUrl + url + "?" + query_string, {
            headers,
            method: "GET",
        });
        if (rst.ok) {
            const data: any = await rst.text()
            const resData = data ? JSON.parse(data) : data;
            if (resData.code > 0) {
                // data.msg
                await appError(resData.msg);
                return {
                    ok: false,
                    data: undefined,
                }
            } else {
                return {
                    ok: true,
                    data: data,
                };
            }
        } else {
            console.warn(`[Warn] Fetch ${url} failed!`, rst.status);
            if (rst.status === 403) {
                sessionStorage.clear();
                window.location.href= "/"
            } else if (rst.status == 404) {
                console.error(`[ERROR] Fetch ${url} Not Found! `);
            }
            return {
                ok: false,
                error: await rst.text(),
            };
        }
    } catch (err) {
        console.error(`[Error] Fetch ${url} failed! `, err);
        return {
            ok: false,
            error: err,
        };
    }
}

export async function Del(
    url: string,
    data?: any,
    headers?: any
): Promise<ResponseBody> {
    try {
        const query_string = _toQueryString(data ?? {});
        const rst = await fetch(DefaultApiUrl + url + "?" + query_string, {
            headers,
            method: "DELETE",
        });
        if (rst.ok) {
            const data: any = await rst.text()
            const resData = data ? JSON.parse(data) : data;
            if (resData.code > 0) {
                // data.msg
                await appError(resData.msg);
                return {
                    ok: false,
                    data: undefined,
                }
            } else {
                return {
                    ok: true,
                    data: data,
                };
            }
        } else {
            console.warn(`[Warn] Fetch ${url} failed!`, rst.status);
            if (rst.status === 403) {
                sessionStorage.clear();
                window.location.href= "/"
            } else if (rst.status == 404) {
                console.error(`[ERROR] Fetch ${url} Not Found! `);
            }
            return {
                ok: false,
                error: await rst.text(),
            };
        }
    } catch (err) {
        console.error(`[Error] Fetch ${url} failed! `, err);
        return {
            ok: false,
            error: err,
        };
    }
}

export async function Post(
    url: string,
    data?: RequestInit
): Promise<ResponseBody> {
    try {
        const rst = await fetch(DefaultApiUrl + url, {
            ...data,
            method: "POST",
        });
        if (rst.headers.get('authorization')) {
            Sessions.write("_3dToken", rst.headers.get('authorization'));
            Actions.login({jwt: rst.headers.get('authorization')})
        }
        if (rst.ok) {
            const data: any = await rst.json()
            if (data.code > 0) {
                // data.msg
                await appError(data.msg);
                return {
                    ok: false,
                    data: undefined,
                }
            } else {
                return {
                    ok: true,
                    data: data,
                };
            }
        } else {
            console.warn(`[Warn] Fetch ${url} failed!`, 'Status: ' + rst.status);
            if (rst.status === 403) {
                sessionStorage.clear();
                window.location.href= "/"
            } else if (rst.status == 404) {
                console.error(`[ERROR] Fetch ${url} Not Found! `);
            }
            return {
                ok: false,
                error: await rst.json(),
            };
        }
    } catch (err) {
        console.error(`[Error] Fetch ${url} failed! `, err);
        return {
            ok: false,
            error: err,
        };
    }
}

export const _toQueryString = (obj: { [x: string]: string | number }) => {
    const str: string[] = [];
    Object.keys(obj).forEach((k) => {
        if (obj[k] !== undefined) {
            str.push(k + "=" + obj[k]);
        }
    });
    return str.join("&");
};

const appError = async (msg: any) => {
    window.location.href= "/"
    var msgmap = Object.entries(msg);
    var msgs = [];
    for (var [key, value] of msgmap) {
        msgs.push(key);
    }
    var err = msgs.join("\r\n");
    message.error(err);
}