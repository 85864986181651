import {Porte, RecikaProvider} from "@recika/web3-provider";
import {errors} from "ethers";

export const DefaultApiUrl = "/api";
export const TCBDefaultApiUrl = "/tcbApi";
export const METADefaultApiUrl = "/metaApi";

export const domain = process.env.REACT_APP_API_DOMAIN;
export const popModel = process.env.REACT_APP_POPUP_MODEL;


export const dappLink = "https://metamask.app.link/dapp/" + domain + "/";

export const shareUrl = "https://" + domain + "/back/share?t=";

export const NetWorks: { [key: string]: string } = {
    Mainnet: "0x1", // 1
    Ropsten: "0x3", // 3
    Rinkeby: "0x4", // 4
    Goerli: "0x5", // 5
    Kovan: "0x2a", // 42
    Mumbai: "0x13881", // 80001
    Polygon: "0x89", // 137,
};
export const getRecikaProviderConfig = (): Promise<RecikaProvider> => {
    const mode = popModel === "development" ? "development" : "production"
    return Porte.createProvider({
        rpcUrl: "http://node2.recika.io:8554",
        expired: 60 * 30, // 30mins
        popupLogo: "https://jis-tcb-test.s3.ap-northeast-1.amazonaws.com/TOKEN/token_test.jpg",
        mode: mode,
        sns: ["Email", "Google"],
        onError: (errors: any) => {
            console.error(errors);
        }
    }) as Promise<RecikaProvider>;
};