/* Profile.tsx*/
import {Input, Button, Form, message, notification, Switch} from 'antd';
import React, {useEffect, useState} from "react";
import avatar1 from "@/assets/avatar/1.png"
import avatar2 from "@/assets/avatar/2.png"
import avatar3 from "@/assets/avatar/3.png"
import avatar4 from "@/assets/avatar/4.png"
import {useAccountState, useAppDispatch} from "@/store";
import {Actions} from "@/store/reducers/account";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "@/router";
import {AccountUpdate, loginApi} from "@/services";

const Profile = () => {

    const [avatarIndex, setAvatarIndex] = useState<number>(1);
    const [userName, setUserName] = useState<string>("");
    const dispatch = useAppDispatch();
    const accountState = useAccountState();
    const navigate = useNavigate();

    const movies = [{id: 1, img: avatar1}, {id: 2, img: avatar2}, {id: 3, img: avatar3}, {id: 4, img: avatar4}];

    const onFinish = async (values: any) => {
        console.log('Success:', values, avatarIndex);
        setAvatarIndex(avatarIndex);
        setUserName(values.userName)
        const res02 = await AccountUpdate({
            nickname: values.userName,
            avatar: avatarIndex,
            publicFlag: values.isPub,
        });
        if (res02) {
            dispatch(Actions.login(
                {userName: values.userName, avatar: avatarIndex, publicFlag: values.isPub}
            ))
            message.info("Change successful.");
            navigate(RoutePaths.HOME);
        } else {
            notification.warning({
                message: "Login failed!",
            });
        }
    };

    function onSwitchChange() {


    }

    useEffect(() => {
        if (accountState) {
            debugger
            console.log(accountState)
            setUserName(accountState.userName ? accountState.userName : "userName");
            setAvatarIndex(accountState.avatar ? accountState.avatar : 1);
        }
    }, [])
    return (
        <div className="bg-black minHeight pt-20">
            <Form
                name="wrap"
                wrapperCol={{flex: 1}}
                colon={false}
                onFinish={onFinish}
                initialValues={{
                    userName: accountState.userName,
                    isPub: accountState.publicFlag,
                }}
            >
                <div className="main-container">
                    <div className="mt-10">
                        <div className="grid text-left ml-10">
                            <h2 className="font-extrabold text-2xl my-1.5 text-white">SETUP</h2>
                            <div className="my-1.5">

                                <div className="flex flex-row">
                                    <div className="basis-1/2">
                                        <span className="font-medium">ニックネームを入力してください</span>
                                        <div className="w-1/4">
                                            <Form.Item label="" name="userName" rules={[{required: true}]}>
                                                <Input className="w-1/2 input-recika" value={userName}
                                                       placeholder="英数字12文字まで"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    {accountState.hasRoom &&
                                        <div className="basis-1/2">
                                            <span className="font-semibold">部屋の状態</span>
                                            <div className="w-1/3 flex justify-between items-baseline">
                                                <span className="text-white">非公開 </span>
                                                <Form.Item valuePropName="checked" name="isPub">
                                                    <Switch defaultChecked/>
                                                </Form.Item>
                                                <span className="text-white">公開</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="mt-5 font-medium">お好きなアパターアイコンを選んでください</div>
                        </div>
                    </div>
                    <div className="grid grid-cols-6 gap-4 justify-items-center items-center mt-5">
                        {movies.map((item, index) => {
                            return (
                                <div className="..." key={index} onClick={() => {
                                    setAvatarIndex(item.id)
                                }}>
                                    <img className="w-28 h-28"
                                         style={{border: item.id == avatarIndex ? "2px dashed #ff8d00" : ""}}
                                         src={item.img} alt=""
                                         width="384" height="512"/>
                                </div>
                            )
                        })}
                    </div>
                    <div className="text-center	mt-10">
                        <Form.Item label=" ">
                            <Button type="primary" shape="round" size="large" className="mainBtn hover:bg-violet-400 "
                                    htmlType="submit">
                                OK
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default Profile;