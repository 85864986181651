import React from 'react'
import Background from '@/assets/home/homeBg.png';
import mobileLogo from '@/assets/logo.png'
import {Button, Col, Row} from "antd";
import discord from "@/assets/discord.png";
import styles from "@/styles/footer.module.scss";
import twitter from "@/assets/twitter.png";
import clsx from "clsx";

const sectionStyle = {
    minHeight: "calc(100vh - 72px)",
    paddingTop: "20px",
    width: "100%",
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "45%",
};
const Mobile: React.FC = () => {

    return (
        <>
            <div className="bg-black minHeight" style={sectionStyle}>
                <div className="ta_c">
                    <img className="icon-img w-11 m-auto" src={mobileLogo} alt=""/>
                </div>
                <div className="text-white w-4/5 mt-10 text-center" style={{marginLeft: '10%', paddingBottom: '5%'}}>
                    <div className="text-white text-xl font-semibold">
                        現在、UniCask Communityは
                        <br/>
                        モバイルからのご利用に
                        <br/>
                        対応しておりません。
                        <br/>
                        PCにてご利用ください。
                        <br/>
                        モバイル版のご提供開始時には、
                        <br/>
                        弊社オフィシャルサイト(unicask.jp)
                        <br/>
                        および各種SNSにて
                        <br/>
                        お知らせいたします。
                    </div>
                    <div className="text-white t1 font-semibold mt-10">
                        Currently, UniCask Community cannot be
                        <br/>
                        accessed from mobile devices.
                        <br/>
                        Please use your PC.
                        <br/>
                        When the mobile version is available,
                        <br/>
                        we will announce it
                        <br/>
                        on our official website (unicask.jp)
                        <br/>
                        as well as on our various SNSs.
                    </div>
                    <div className="mt-10">
                        <a href="https://unicask.jp/" target="_blank">
                            <Button type="primary" shape="round" size="large" className="mainBtn">
                                UNICASK.jp
                            </Button>
                        </a>
                    </div>

                </div>
            </div>
            <Footer/>
        </>

    )
}

const Footer = () => {
    function openUrl(val: string) {
        window.open(val, "_blank")
    }

    return <footer className="bgMain">
        <div className="main-container">
            <div className="flex justify-center items-center">
                <img src={discord} alt="" className={clsx(styles.logoImg, "cursor-pointer")}
                     onClick={() => openUrl("https://twitter.com/UniCask_jp")}/>
                <img src={twitter} alt="" className={clsx(styles.logoImg, "cursor-pointer")}
                     onClick={() => openUrl("https://discord.com/invite/Bxa3xfhGyJ")}/>
            </div>
            <div className="text-center m-auto">@2022 UniCask</div>
        </div>
    </footer>
}

export default Mobile