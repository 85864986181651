import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import {Unity, useUnityContext} from "react-unity-webgl";
import Header from "../Layout/Header";
import {Button} from "antd";
import styles from "../../styles/room.module.scss"
import {useLocation, useParams} from "react-router-dom";
import {RoutePaths} from "@/router";
import {getNftList, GLoginType, sTokens} from "@/utils";
import {room_detail} from "@/services/room";
import {CloseCircleOutlined} from "@ant-design/icons";
import clsx from "clsx";
import {useAccountState} from "@/store";

const Room: FunctionComponent = () => {

    const accountState = useAccountState();

    const location = useLocation();

    const param: any = useParams();

    const {
        loadingProgression,
        unityProvider,
        isLoaded,
        requestFullscreen,
        requestPointerLock,
        sendMessage,
        initialisationError,
        addEventListener,
        removeEventListener,
        takeScreenshot,
        unload,
    } = useUnityContext({
        codeUrl: "/Build14/recika_wine_bar_vr.wasm",
        dataUrl: "/Build14/recika_wine_bar_vr.data",
        frameworkUrl: "/Build14/recika_wine_bar_vr.framework.js",
        loaderUrl: "/Build14/recika_wine_bar_vr.loader.js",
        streamingAssetsUrl: "/StreamingAssets14",
        companyName: "Recika",
        productName: "Wine Bar VR",
        productVersion: "1.0.0",
        webglContextAttributes: {
            preserveDrawingBuffer: true,
        },
    });

    const [btnClickFlag, setBtnClickFlag] = useState<boolean>(true)
    const [nftShowFlag, setNftShowFlag] = useState<boolean>(false);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [loadCompleted, setLoadCompleted] = useState<boolean>(false);
    const [iframeSrc, setIframeSrc] = useState<string>();


    useEffect(() => {
        if (isLoaded) {
            if (location.pathname.includes(RoutePaths.PUBLIC_ROOM)) {
                sendMessage('[System]AppExternalCallbackManager', 'OnCallLoadRoom', 2);
                pubRoomInit();
            } else if (location.pathname.includes(RoutePaths.ROOM)) {
                sendMessage('[System]AppExternalCallbackManager', 'OnCallLoadRoom', 1);
                roomInit(param.roomId);
            } else if (location.pathname.includes(RoutePaths.MY_ROOM)) {
                sendMessage('[System]AppExternalCallbackManager', 'OnCallLoadRoom', 1);
                roomInit(param.roomId);
            }
            sendMessage('PlayerArmature', 'PauseGame');
        }
    }, [isLoaded]);

    function pubRoomInit() {
        let cask = {
            casklists: [
                "https://jis-tcb-dev.s3.ap-northeast-1.amazonaws.com/cask/ny_v0.02.png",
                "https://jis-tcb-dev.s3.ap-northeast-1.amazonaws.com/cask/verylonganimals_v0.02.png",
                "https://jis-tcb-dev.s3.ap-northeast-1.amazonaws.com/cask/polygon_v0.02.png",
                "https://jis-tcb-dev.s3.ap-northeast-1.amazonaws.com/cask/DanshiSenka_v0.02.png",
                "https://jis-tcb-dev.s3.ap-northeast-1.amazonaws.com/cask/metaani_v0.02.png",
                "https://jis-tcb-dev.s3.ap-northeast-1.amazonaws.com/cask/genesis_v0.02.png",
            ]
        }
        sendMessage('[System]AppExternalCallbackManager', 'OnCallLoadPublicRoomImageData', JSON.stringify(cask));
    }

    function escFunction(event: any) {
        if (event.keyCode === 27) {
            setBtnClickFlag(true);
            sendMessage('PlayerArmature', 'PauseGame');
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        document.addEventListener('pointerlockchange', lockChangeAlert, false);
        return () => {
            document.addEventListener("keydown", escFunction, false);
            document.removeEventListener('pointerlockchange', lockChangeAlert, false);
        };
    });

    const OpenNftDetailPopup = useCallback((nftDetailUrl: any) => {
        if (location.pathname.includes(RoutePaths.PUBLIC_ROOM)) {
            window.open('https://unicask.com/home', 'open_window');
            return;
        } else {
            if (nftDetailUrl) {
                setNftShowFlag(true);
                setBtnClickFlag(false);
                setIframeSrc(nftDetailUrl);
                sendMessage('PlayerArmature', 'PauseGame');
                document.exitPointerLock();
            }
        }
    }, []);

    const OnSceneLoadCompleted = useCallback(() => {
        //loading  completed
        setLoadCompleted(true);
        sendMessage('PlayerArmature', 'PauseGame');
    }, [loadCompleted]);

    useEffect(() => {
        addEventListener("OpenNftDetailPopup", OpenNftDetailPopup);
        addEventListener("OnSceneLoadCompleted", OnSceneLoadCompleted);
        return () => {
            removeEventListener("OpenNftDetailPopup", OpenNftDetailPopup);
            removeEventListener("OnSceneLoadCompleted", OnSceneLoadCompleted);
        };
    }, [addEventListener, removeEventListener, OpenNftDetailPopup, OnSceneLoadCompleted]);


    function lockChangeAlert() {
        if (document.pointerLockElement) {
            console.log('The pointer lock status is now locked mousehide', new Date());
            sendMessage('PlayerArmature', 'PlayGame');// callback pause
        } else {
            console.log(nftShowFlag)
            sendMessage('PlayerArmature', 'PauseGame');
            if (nftShowFlag) {
                setBtnClickFlag(false);
            } else {
                setBtnClickFlag(true);
            }
        }

    }

    async function roomInit(roomId: number) {
        const res = await room_detail(roomId);
        if (res && res.data) {
            const {roomId, walletType, ownerAddress, ownerName, ownerAvatar, metaMaskAddr} = res.data;
            let params: any = {
                walletType: walletType,
                address: ownerAddress,
                nftLists: [],
            }

            const nfts: any = await getNftList(ownerAddress, GLoginType.CORECIKA);
            if (nfts && nfts.length > 0) {
                const url = process.env.REACT_APP_IFRAME_NFT_DETAIL;
                const collection = nfts.map((item: any, index: number) => {
                        return {
                            tokenid: item.tokenId,
                            tokenName: item.name,
                            owner: ownerAddress,
                            image_url: item.image,
                            external_detail_url: url + "?tokenId=" + item.tokenId + "&walletType=" + walletType + "&address=" + ownerAddress + "&roomId=" + roomId,
                        }
                    }
                );
                console.log(collection, "corecika nft list")
                params.nftLists = collection;
            }
            //matemask
            const {conAddr, tokenLists} = await sTokens("eth");
            if (tokenLists && tokenLists.length > 0 && conAddr) {
                debugger
                let WALLET_ADDRESS: string = "";
                if (location.pathname.includes(RoutePaths.MY_ROOM)) {
                    WALLET_ADDRESS = accountState.metaMaskAddr ? accountState.metaMaskAddr : metaMaskAddr
                } else if (location.pathname.includes(RoutePaths.ROOM)) {
                    WALLET_ADDRESS = metaMaskAddr
                }
                if (WALLET_ADDRESS) {
                    let nftLists: any = await getNftList(WALLET_ADDRESS, GLoginType.MATEMASK)
                    //corecika == 1 matemask == 2
                    const baseUrl = process.env.REACT_APP_IFRAME_NFT_DETAIL;
                    const collection = nftLists.map((item: any, index: number) => {
                            return {
                                tokenid: item.id,
                                tokenName: item.name,
                                owner: ownerAddress,
                                image_url: item.image_url,
                                external_detail_url: baseUrl + "?tokenId=" + item.id + "&walletType=" + 2 + "&address=" + WALLET_ADDRESS + "&roomId=" + roomId,
                            }
                        }
                    );
                    console.log(collection, "metamask nft list")
                    params.nftLists = params.nftLists.concat(collection);
                }
            }
            console.log(params.nftLists)
            sendMessage('[System]AppExternalCallbackManager', 'OnCallUpdateData', JSON.stringify(params))
        }
    }

    function play() {
        setNftShowFlag(false);
        requestPointerLock();
        sendMessage('PlayerArmature', 'PlayGame');// callback pause
    }

    function enterRoom() {
        requestPointerLock();
        sendMessage('PlayerArmature', 'PlayGame');// callback pause
        setBtnClickFlag(false)
    }

    return (
        <div key={location.pathname}>
            {btnClickFlag && <div className={styles.pop}
                                  style={{background: !loadCompleted ? "black" : "#000000b3"}}>
                <Header/>
                {!loadCompleted &&
                <div className="relative">
                    <div className="absolute w-full" style={{left: "12.5%", marginTop: "2%"}}>
                        <video className="w-3/4" src={require('../../assets/room.mp4')} autoPlay={true} loop={true}
                               playsInline={true} muted={true}></video>
                    </div>
                </div>
                }
                <div className={styles.canvasDivBtnDiv}>
                    <div className="text-left">
                        <div className="text-white text-4xl">
                            {location.pathname.includes(RoutePaths.MY_ROOM) ? "MY ROOM" : "UNICASK ROOM"}
                        </div>
                        <div className="mt-4">
                            <Button type="primary" shape="round" size="large" disabled={!loadCompleted}
                                    className={clsx("mainBtn", loadCompleted ? "" : "disabled-btn")}
                                    onClick={() => enterRoom()}>
                                {loadCompleted ? "Enter Room" : "Now Loading"}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="relative">
                    <div className="absolute w-full " style={{left: "35%", top: "80vh"}}>
                        <img className="w-3/12" src={require('../../assets/gifani2.gif')} alt="gif"/>
                    </div>
                </div>
            </div>
            }
            {nftShowFlag && <div className={styles.pop} style={{background: "none"}}>
                <div className={styles.canvasXbtn}>
                    <div className="nft-3d-x">
                        <CloseCircleOutlined style={{fontSize: '34px', color: 'white'}} onClick={() => play()}/>
                    </div>
                </div>
                <div className={styles.canvasDivBtnDiv} style={{top: "10%"}}>
                    <iframe
                        src={iframeSrc}
                        height={"600px"} width={"800px"}/>
                </div>
            </div>
            }

            <Unity
                unityProvider={unityProvider}
                className={styles.canvasDiv}
                ref={canvasRef}
            />
        </div>
    );
}

export default Room;