import React from "react";
import {Col, Row} from 'antd';
import styles from "../../styles/footer.module.scss";
import logo from '../../assets/logo-unicask.png'
import discord from '../../assets/discord.png'
import twitter from '../../assets/twitter.png'
import clsx from "clsx";

const Footer = () => {

    function openUrl(val: string) {
        window.open(val, "_blank")
    }

    return <footer className="footer bgMain">
        <div className="main-container">
            <Row>
                <Col className="gutter-row" span={6}>
                    <div className="text-footer leading-5">
                        <img className="img" style={{width: "50%"}} src={logo} alt=""/>
                    </div>
                </Col>
                <Col className="gutter-row" span={6} offset={12}>
                    <div className="flex justify-end items-center">
                        <img src={discord} alt="" className={clsx(styles.logoImg, "cursor-pointer")}
                             onClick={() => openUrl("https://twitter.com/UniCask_jp")}/>
                        <img src={twitter} alt="" className={clsx(styles.logoImg, "cursor-pointer")}
                             onClick={() => openUrl("https://discord.com/invite/Bxa3xfhGyJ")}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="gutter-row" span={6} offset={18}>
                    <div className={styles.footerFont} style={{textAlign: "right"}}>@2022 UniCask</div>
                </Col>
            </Row>
        </div>

    </footer>
}
export default Footer