import {Del, Get, Post, Sessions} from "@/utils";


export const room_list_top = async (): Promise<any | null> => {
    const res = await Get("/room/listTop");
    if (res.ok && res.data) {
        return JSON.parse(res.data);
    } else {
        return null;
    }
};

export const room_detail = async (roomId: number): Promise<any> => {
    const res = await Get("/room/" + roomId);
    if (res.ok && res.data) {
        return JSON.parse(res.data);
    } else {
        return null;
    }
};

export const opensea_tokens = async (): Promise<any> => {
    const res = await Get("/account/tokenIds");
    if (res.ok && res.data) {
        return JSON.parse(res.data);
    } else {
        return null;
    }
};

export const add_room = async (): Promise<any> => {
    let headers = {
        'content-type': 'application/json'
    } as any;
    if (Sessions.read("_3dToken")) {
        headers.Authorization = Sessions.read("_3dToken");
    }
    const res = await Post("/account/room", {
        body: null,
        headers: headers
    });
    return res.data;
};

export const del_room = async (): Promise<any> => {
    let headers = {
        'content-type': 'application/json'
    } as any;
    if (Sessions.read("_3dToken")) {
        headers.Authorization = Sessions.read("_3dToken");
    }
    const res = await Del("/account/room", null, headers);
    if (res.ok && res.data) {
        return JSON.parse(res.data);
    } else {
        return null;
    }
};
