// type
export enum GLoginType {
    CORECIKA = "corecika",
    MATEMASK = "mateMask",
}

export enum NFT_TYPE {
    METAMASK_NFTS = "METAMASK_NFTS",
    CORECIKA_NFTS = "CORECIKA_NFTS",
}

export const collectionName = [
    "UniCask-TestNet",
    "Springbank1991#320"
]