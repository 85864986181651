import React, {useEffect} from 'react';
import './App.scss';
import {Outlet, useNavigate} from "react-router-dom";
import Header from "./pages/Layout/Header";
import Footer from "./pages/Layout/Footer";
import 'antd/dist/antd.min.css';
import isMobile from 'ismobilejs';
import {RoutePaths} from "@/router";


function App() {
    const navigate = useNavigate();

    useEffect(() => {
        // Listen on the accountsChanged/chainChanged event
        // if they are changed, then hint user to re-sign in
        if (
            typeof (window as any).ethereum != "undefined" &&
            typeof (window as any).ethereum._events != "undefined"
        ) {
            if ((window as any).ethereum._events["accountsChanged"] !== undefined) {
                delete (window as any).ethereum._events["accountsChanged"];
            }
            if ((window as any).ethereum._events["accountsChanged"] === undefined) {
                (window as any).ethereum.on("accountsChanged", async () => {
                    // notification.warning({
                    //     message: "Wallet account was changed, please sign up again!",
                    // });
                });
            }

            if ((window as any).ethereum._events["chainChanged"] !== undefined) {
                delete (window as any).ethereum._events["chainChanged"];
            }
            if ((window as any).ethereum._events["chainChanged"] === undefined) {
                (window as any).ethereum.on("chainChanged", async () => {
                    // notification.warning({
                    //     message: "Wallet network was changed, please sign up again!",
                    // });
                });
            }
        }
        return () => {
            // Delete the accountsChanged/chainChanged events
            if (
                typeof (window as any).ethereum != "undefined" &&
                typeof (window as any).ethereum._events != "undefined"
            ) {
                if ((window as any).ethereum._events["accountsChanged"] !== undefined) {
                    delete (window as any).ethereum._events["accountsChanged"];
                }
                if ((window as any).ethereum._events["chainChanged"] !== undefined) {
                    delete (window as any).ethereum._events["chainChanged"];
                }
            }
        };
    },);

    useEffect(()=>{
        if (isMobile(window.navigator).any) {
            navigate(RoutePaths.IS_MOBILE);
        }

    })

    return (
        <div className="App">
            <Header/>
            <Outlet/>
            <Footer/>
        </div>
    );
}

export default App;
