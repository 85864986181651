import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import {Unity, useUnityContext} from "react-unity-webgl";
import Header from "../Layout/Header";
import {Button} from "antd";
import styles from "../../styles/room.module.scss"
import {useAccountState} from "@/store";
import {useLocation, useParams} from "react-router-dom";
import {RoutePaths} from "@/router";

const Room: FunctionComponent = () => {

    const accountState = useAccountState();

    const location = useLocation();

    const param: any = useParams();

    const {
        loadingProgression,
        unityProvider,
        isLoaded,
        requestFullscreen,
        requestPointerLock,
        sendMessage,
        initialisationError,
        addEventListener,
        removeEventListener,
        takeScreenshot,
        unload,
    } = useUnityContext({
        codeUrl: "/Build101/BuildpublicAngo.wasm",
        dataUrl: "/Build101/BuildpublicAngo.data",
        frameworkUrl: "/Build101/BuildpublicAngo.framework.js",
        loaderUrl: "/Build101/BuildpublicAngo.loader.js",
        streamingAssetsUrl: "/StreamingAssets101",
        companyName: "Recika",
        productName: "Wine Bar VR",
        productVersion: "1.0.0",
        webglContextAttributes: {
            preserveDrawingBuffer: true,
        },
    });

    const minBtn = {
        minWidth: "180px",
        background: "#344b9b",
        borderColor: "#344b9b",
        color: "white",
        margin: "0 10px 0 10px ",
        fontWeight: "600",
    }

    const [btnClickFlag, setBtnClickFlag] = useState<boolean>(true)
    const [nftShowFlag, setNftShowFlag] = useState<boolean>(false);
    const [rotation, setRotation] = useState(0);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [pause, setPause] = useState<boolean>(false);
    const [initFlag, setInitFlag] = useState<boolean>(false);


    const handleClickedPosition = useCallback((x: number, y: number) => {
        console.log("Clicked Position:", {x, y});
    }, []);


    useEffect(() => {
        addEventListener("RotationDidUpdate", setRotation);
        addEventListener("ClickedPosition", handleClickedPosition);

        return () => {
            removeEventListener("RotationDidUpdate", setRotation);
            removeEventListener("ClickedPosition", handleClickedPosition);
        };
    }, [addEventListener, removeEventListener, handleClickedPosition]);

    useEffect(() => {
        if (isLoaded) {
            setTimeout(() => {
            }, 2000)
        }
    }, [isLoaded]);

    function enterRoom() {
        requestPointerLock();
        if (initFlag) {
            sendMessage('[System]AppExternalCallbackManager', 'CallPause'); // callback pause
        }
        setBtnClickFlag(false)
    }

    return (
        <div key={location.pathname}>
            {btnClickFlag && <div className={styles.pop}>
                <Header/>
                <div className={styles.canvasangoDivBtnDiv}>
                    {!isLoaded &&
                    <div id="unity-progress-bar-empty">
                        <div id="unity-progress-bar-full" style={{width: `${100 * loadingProgression}%`}}></div>
                    </div>
                    }
                    {isLoaded &&
                    <Button type="primary" shape="round" size="large" style={minBtn} disabled={!isLoaded}
                            onClick={() => enterRoom()}>Enter Room </Button>
                    }

                </div>
            </div>
            }
            {nftShowFlag && <div className={styles.pop}>
                <Header/>
                <div className={styles.canvasDivBtnDiv} style={{top: "10%"}}>
                    <iframe
                        src={"http://localhost:3000/nft3dDetail?tokenId=55298652&walletType=1&address=0x9059bBaf19cbAEb573df0f3BefFb1538C2Fddd41"}
                        height={"600px"} width={"800px"}/>
                </div>
            </div>
            }

            <Unity
                unityProvider={unityProvider}
                className={styles.canvasDiv}
                ref={canvasRef}
            />
        </div>
    );
}

export default Room;
